import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Stack, Text, useColorMode } from '@chakra-ui/react';

interface LayoutInputTextProps {
    fontSize?: number | string
    label: string;
    defaultValue: string;
    onChange?: (text: string) => void;
    onBlur?: () => void
    h?: string | number
}
export const LayoutInputText: React.FC<LayoutInputTextProps> = React.memo(({ onBlur, fontSize = 12, label, defaultValue = '', onChange, h = '60px' }) => {
    const { colorMode } = useColorMode();
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.textContent = defaultValue;
        }
    }, [defaultValue]);

    const handleInput = useCallback(() => {
        if (contentRef.current && onChange) {
            onChange(contentRef.current.textContent || '');
        }
    }, [onChange]);

    const _onBlur = useCallback(() => {
        onBlur && onBlur()
    }, [onBlur])


    return (
        <Stack fontSize={fontSize}>
            <Box>
                <Text>{label}</Text>
            </Box>
            <Box
                ref={contentRef}
                suppressContentEditableWarning={true}
                onInput={handleInput}


                w={'100%'}
                h={h}
                overflowY={'auto'}
                _focus={{ boxShadow: 'none', border: '1px', outline: 1, borderColor: 'teal.300' }}
                contentEditable
                px={3}
                py={2}
                fontSize={fontSize}
                bg={colorMode === 'dark' ? 'gray.900' : 'white'}
                onBlur={_onBlur}
                border={'1px'}
                borderColor={'Line'+colorMode}
                rounded={'4px'}

            />


        </Stack>
    );
});
