import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Center,
    HStack,
    Img,
    Input,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spacer,
    Stack,
    Text,
    useColorMode,
} from '@chakra-ui/react';
import { OmiButton, OmiIconButton } from '.';
import { FiTrash2, FiUpload } from 'react-icons/fi';
import { randStr } from '@utils/randStr';

interface ImageUploadBoxProps {
    src?: string;
    type: 'Logo' | 'Favicon' | 'Cover' | 'Photo';
    onSelected?: (src: string) => void;
    onResize?: (h: number) => void;
    disableResize?: boolean;
    h?: number;
}
export const ImageUploadBox: React.FC<ImageUploadBoxProps> = React.memo(({ src, type, onSelected, onResize, disableResize = true, h = 27 }) => {
    const { colorMode } = useColorMode();
    const [selectedImage, setSelectedImage] = useState<string | null | undefined>(src);
    const [imageHeight, setHeight] = useState<number>(h);

    const RAND_ID = randStr()

    const handleImageChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSelectedImage(reader.result as string);
                };
                reader.readAsDataURL(file);
            }
        },
        [onSelected, setSelectedImage]
    );

    const _onRemove = useCallback(() => {
        setSelectedImage('');
    }, [onSelected]);

    const _onChangeHeight = useCallback(
        (val: number) => {
            setHeight(val);
            if (onResize) onResize(val);
        },
        [onResize]
    );

    useEffect(() => {
        if (onSelected) onSelected(selectedImage as string);
    }, [selectedImage]);

    return (
        <>
            <Input
                id={`image-upload-input-${type}${RAND_ID}`}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                size="md"
                variant="flushed"
                display="none"
            />
            <Stack spacing={3}>
                <HStack fontSize={12} spacing={0}>
                    <Box>
                        <Text>{type}</Text>
                    </Box>
                    <Spacer />
                    <Box>
                        <OmiButton
                            onClick={() => document.getElementById('image-upload-input-' + type + RAND_ID)?.click()}
                            width={116}
                            fontSize={12}
                            size={'xs'}
                            leftIcon={<FiUpload />}
                        >
                            Upload
                        </OmiButton>
                    </Box>
                </HStack>
                {selectedImage && selectedImage !== '' && (
                    <Center as={Stack} pos={'relative'} w={'100%'} h={110} bg={'Line' + colorMode} spacing={0}>
                        <Img src={selectedImage} height={`${imageHeight}px`} bgSize={'contain'} />
                        {!disableResize && (
                            <Box pos={'absolute'} height={'50%'} right={'10px'} bottom={'10px'}>
                                <Slider
                                    orientation="vertical"
                                    onChange={_onChangeHeight}
                                    size={'sm'}
                                    aria-label="slider-ex-2"
                                    colorScheme="dark"
                                    defaultValue={imageHeight}
                                >
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb />
                                </Slider>
                            </Box>
                        )}
                        <OmiIconButton
                            onClick={_onRemove}
                            pos={'absolute'}
                            right={1}
                            top={1}
                            size={'xs'}
                            variant={'ghost'}
                            colorScheme="dark"
                            aria-label="remove logo"
                            icon={<FiTrash2 />}
                        />
                    </Center>
                )}
            </Stack>
        </>
    );
});
