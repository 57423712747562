import { Button, ButtonProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

type OmiButtonProps = {
    children: React.ReactNode;
} & ButtonProps;

export const OmiButton = React.memo(forwardRef<HTMLButtonElement, OmiButtonProps>(({ children, ...REST }, ref) => (
    <Button ref={ref} px={3} size={'sm'} fontSize={14} pt={0.8} fontWeight={'normal'} borderRadius={3} {...REST}>
        {children}
    </Button>
)));
