export function hexToRgba(hex: string, alpha: number = 1): string {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return the rgba color string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

