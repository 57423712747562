import React from 'react';
import { Box, HStack, Text, ButtonProps, useDisclosure, Spacer } from '@chakra-ui/react';
import { OmiButton } from '.';
import { FiMenu } from 'react-icons/fi';
import { OmiModal } from './OmiModal';
import { randStr } from '@utils/randStr';





type LayoutCustomizeListProps = {

    label: string;
    list?: string[];
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | 'full'
    onClick?: () => void
    render?: (onClose: Function) => React.ReactNode;
    isNoPadding?: boolean
} & ButtonProps
export const LayoutCustomList: React.FC<LayoutCustomizeListProps> = React.memo(({ label, render, isNoPadding = false, size = 'md', onClick, ...REST }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box id={randStr()}>

            <OmiModal id={randStr()} title={label} size={size} isOpen={isOpen} onClose={onClose} isNoPadding={isNoPadding} >
                <Box>
                    {render && render(onClose)}
                </Box>
            </OmiModal>

            <HStack spacing={0} fontSize={12}>
                <Box>
                    <Text>{label}</Text>
                </Box>
                <Spacer />
                <Box>
                    <OmiButton onClick={isOpen ? onClose : onOpen} textAlign="left" justifyContent="flex-start" width={116} fontSize={12} size={'xs'} leftIcon={<FiMenu />} {...REST}>
                        Customize
                    </OmiButton>
                </Box>
            </HStack>
        </Box>
    );
});
