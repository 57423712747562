import React, { useCallback, useEffect, useState } from 'react';
import { Box, HStack, Spacer, Switch, Text } from '@chakra-ui/react';

interface LayoutBooleanBoxProps {
    fontSize?: number | string;
    label: string;
    defaultValue: boolean;
    onChange?: (value: boolean) => void;
}

export const LayoutBooleanBox: React.FC<LayoutBooleanBoxProps> = React.memo(({ fontSize = 12, label, defaultValue = true, onChange }) => {
    const [localValue, setLocalValue] = useState<boolean>(defaultValue);

    const _onChange = useCallback(() => {
        setLocalValue((prev) => {
            const newValue = !prev;
            if (onChange) onChange(newValue);
            return newValue;
        });
    }, [onChange]);

    useEffect(() => {
        setLocalValue(defaultValue);
    }, [defaultValue]);

    return (
        <HStack fontSize={fontSize} spacing={0}>
            <Box>
                <Text>{label}</Text>
            </Box>
            <Spacer />
            <Box>
                <Switch size={fontSize === 12 ? 'sm' : 'md'} onChange={_onChange} isChecked={localValue} colorScheme="cyan" />
            </Box>
        </HStack>
    );
});
