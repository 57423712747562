import React, { useCallback } from "react"

import { Box, HStack, Spacer, Text } from "@chakra-ui/react"
import { OmiSelect } from "."
import { toSelectList } from "../utils"
import { TSelectItem } from "../common/types"


interface LayoutSelectBoxProps {
    label: string
    defaultValue: string
    list: string[]
    onChange?: (data: TSelectItem) => void
}
export const LayoutSelectBox: React.FC<LayoutSelectBoxProps> = React.memo(({ label, defaultValue, list, onChange }) => {

    const _onChange = useCallback((data: TSelectItem) => {
        if (onChange) onChange(data)
    }, [onChange])

    return (
        <HStack fontSize={12} spacing={0}>
            <Box>
                <Text>{label}</Text>
            </Box>
            <Spacer />
            <Box >
                <OmiSelect onChange={_onChange} value={defaultValue || ''} list={toSelectList(list || [])} />
            </Box>
        </HStack>
    )
})