import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';



export const OmiIconButton: React.FC<IconButtonProps> = React.memo(({ ...REST }) => {

    return (
        <IconButton size={'sm'}  borderRadius={3} {...REST} />


    );
});