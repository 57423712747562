const cache: { [key: number]: string } = {};

export const formatFileSize = (bytes: number): string => {
    if (cache[bytes]) return cache[bytes]

    if (bytes < 1024) {
        cache[bytes] = `${bytes} bytes`;
        return cache[bytes];
    }

    const units = ['KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    let value = bytes / 1024;

    while (value >= 1024 && unitIndex < units.length - 1) {
        value /= 1024;
        unitIndex++;
    }

    const result = `${value.toFixed(2)} ${units[unitIndex]}`;
    cache[bytes] = result; // Store computed result in cache
    return result;
};
