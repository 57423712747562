import React from 'react';
import { Input, InputProps, useColorMode } from '@chakra-ui/react';

export const OmiInput = React.memo(
    React.forwardRef<HTMLInputElement, InputProps>(({ ...REST }, ref) => {
        const { colorMode } = useColorMode();

        //const lineColor = colorMode === 'light' ? '#ECC94C' : '#ECC94C'

        return (
            <Input
                ref={ref}
                rounded="4px"
                fontSize={14}
                color={colorMode === 'dark' ? 'white' : 'gray.600'}
                borderColor={colorMode === 'dark' ? 'gray.700' : 'gray.300'}
                bgColor={colorMode === 'dark' ? 'gray.900' : 'white'}
                _focus={{
                    border: 0, // Custom focus style
                    boxShadow:`0 0 0 1px #ECC94B`,
                }}
                size="md"
                {...REST}
            />
        );
    })
);
