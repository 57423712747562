export function rgbaToHex(rgba: string): string {
    const rgbaValues = rgba.replace('rgba(', '').replace(')', '').split(',');
    const r = parseInt(rgbaValues[0]);
    const g = parseInt(rgbaValues[1]);
    const b = parseInt(rgbaValues[2]);
    const hexColor = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    return hexColor;
}
 
export function getOpacity(rgba: string) {
    if (rgba.indexOf('rgba') > -1) {
        
        return rgba.split('rgba(')[1].split(')')[0].split(',').slice(-1);
    }

    return 1;
}

