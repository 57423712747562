import React, { useCallback } from 'react';
import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { OmiSelectColorList } from '.';
import { TSelectItem } from '../common/types';
import { getOpacity, rgbaToHex } from '@utils/rgbaToHex';

export const COLOR_LIST: TSelectItem[] = [
    { Code: '#111111', Label: 'Black' },
    { Code: '#ffffff', Label: 'White' },
    { Code: '#A3A3A3', Label: 'Gray' },
    { Code: '#E53E3E', Label: 'Red' },
    { Code: '#F6AD55', Label: 'Orange' },
    { Code: '#ECC94B', Label: 'Yellow' },
    { Code: '#38A169', Label: 'Green' },
    { Code: '#319795', Label: 'Teal' },
    { Code: '#2B6CB0', Label: 'Blue' },
    { Code: '#00A3C4', Label: 'Cyan' },
    { Code: '#6B46C1', Label: 'Purple' },
    { Code: '#ED64A6', Label: 'Pink' },
];

interface ColorSelectBoxProps {
    label: string;
    defaultValue: string;
    onChange?: (color: string) => void;
    isOpacity?: boolean;
}
export const ColorSelectBox: React.FC<ColorSelectBoxProps> = React.memo(({ label, defaultValue, onChange, isOpacity = false }) => {
    const _onChange = useCallback(
        (color: string) => {
            if (onChange) onChange(color);
        },
        [onChange]
    );

    const hex = defaultValue.indexOf('rgba') > -1 ? rgbaToHex(defaultValue.replace(String(getOpacity(defaultValue)), '1')) : defaultValue || '';
    const opacity = Number(getOpacity(defaultValue)) * 100;

    return (
        <HStack fontSize={12}>
            <Box >
                <Text>{label}</Text>
            </Box>
            <Spacer />
            <Box>
                <OmiSelectColorList
                    isOpacity={isOpacity}
                    opaci={opacity}
                    id={String(new Date().getTime())}
                    onChange={_onChange}
                    value={hex}
                    list={COLOR_LIST}
                />
            </Box>
        </HStack>
    );
});
