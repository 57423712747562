import React, { useCallback, useEffect, useState } from 'react';

import { PiCaretUpDownLight } from 'react-icons/pi';
import { Button, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useColorMode } from '@chakra-ui/react';

import { TSelectItem } from '../common/types';

interface OmiSelectProps {
    w?: number | string;
    list: TSelectItem[];
    value: string;
    onChange?: (item: TSelectItem) => void;
    size?: string;
    variant?: string;
}

export const OmiSelect: React.FC<OmiSelectProps> = React.memo(({ w = '115px', list, value = 'Label', size = 'xs', variant = 'solid', onChange }) => {
    const { colorMode } = useColorMode();
    const [selected, setSelected] = useState<string>(value);

    const _onChange = useCallback(
        (item: TSelectItem) => {
            setSelected(item?.Label);
            if (onChange) onChange(item);
        },
        [onChange]
    );

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <Menu>
            <MenuButton
                as={Button}

                borderRadius={3}
                fontWeight={'normal'}
                textAlign="left"
                justifyContent="space-between"
                w={w}
                px={2}
                pl={3}
                size={size}
                variant={variant}
                rightIcon={<PiCaretUpDownLight size={12} />}

            >
                <Text fontSize={size == 'md' ? 14 : size}>
                    {selected.indexOf(',') > -1 ? selected.split(',')[0] : selected}
                </Text>
            </MenuButton>

            <Stack>
                <MenuList
                    p={'5px'} bgColor={colorMode === 'dark' ? 'Line' + colorMode : 'white'}
                    borderColor={colorMode === 'dark' ? 'gray.600' : 'gray.200'}
                >
                    {list.map((item) => {
                        return (
                            <MenuItem
                                onClick={() => _onChange(item)}
                                _hover={{ bg: colorMode === 'dark' ? '#464A50' : 'gray.200' }}
                                bgColor={'transparent'}
                                key={item.Code}
                                borderRadius={3}
                            >
                                <Text fontSize={size == 'md' ? 14 : size}>{item?.Label}</Text>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Stack>
        </Menu>
    );
});
