import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { HStack, Modal, ModalBody, Text, ModalContent, ModalProps, useColorMode, Spacer, ModalOverlay, Stack, Box } from '@chakra-ui/react';
import { OmiIconButton } from './OmiIconButton';
import { FiX } from 'react-icons/fi';

interface ModalViewProps {
    title: string;
    rightButton?: string | React.ReactNode;
    children: React.ReactNode;
}
export const ModalView: React.FC<ModalViewProps> = ({ title, rightButton, children }) => {
    return (
        <Stack spacing={0} fontFamily={'Roboto'}>
            <HStack px={5} py={3}>
                <Text fontSize={'24px'} fontFamily={'Roboto'}>
                    {title}
                </Text>
                <Spacer />
                {rightButton ? <Box>{rightButton}</Box> : null}
            </HStack>
            <Stack px={5} pb={5}>
                {children && children}
            </Stack>
        </Stack>
    );
};

interface HeaderProps {
    title: string;
    isNoPadding?: boolean;
    onClose?: () => void;
}
const Header = forwardRef<HTMLDivElement, HeaderProps>(({ title, onClose, isNoPadding = false }, ref) => {
    const { colorMode } = useColorMode();

    if (isNoPadding)
        return (
            <OmiIconButton
                pos={'absolute'}
                mt={3}
                right={3}
                onClick={onClose}
                variant={'ghost'}
                size={'xs'}
                aria-label="close button"
                icon={<FiX />}
            />
        );

    return (
        <HStack
            ref={ref}
            pos={'relative'}
            borderTopLeftRadius={6}
            borderTopRightRadius={6}
            px={2}
            pl={7}
            pr={3}
            pt={4}
            borderBottom={'0px'}
            borderColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
            cursor={'move'}
        >
            <Text fontSize={20} color={colorMode === 'dark' ? 'gray.100' : 'gray.600'} fontWeight={'500'} fontFamily={'Inter'}>
                {title}
            </Text>
            <Spacer />
            <OmiIconButton
                pos={'absolute'}
                mt={-2}
                right={3}
                onClick={onClose}
                variant={'ghost'}
                size={'sm'}
                aria-label="close button"
                icon={<FiX />}
            />
        </HStack>
    );
});

interface Position {
    x: number;
    y: number;
}

type OmiModalProps = {
    id?: string;
    title?: string;
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    isNoPadding?: boolean;
} & ModalProps;

export const OmiModal: React.FC<OmiModalProps> = React.memo(({ title = 'modal', children, isOpen, id, isNoPadding = false, onClose, ...rest }) => {
    const { colorMode } = useColorMode();
    const [position, setPosition] = useState<Position>({ x: 0, y: 50 });
    const dragOffset = useRef<Position>({ x: 0, y: 0 });
    const headerRef = useRef<HTMLDivElement>(null);

    const onDrag = useCallback((event: MouseEvent) => {
        setPosition({
            x: event.clientX - dragOffset.current.x,
            y: event.clientY - dragOffset.current.y,
        });
    }, []);

    const stopDrag = useCallback(() => {
        document.removeEventListener('mousemove', onDrag);
        document.removeEventListener('mouseup', stopDrag);
    }, [onDrag]);

    const startDrag = useCallback(
        (event: MouseEvent) => {
            if (event.button !== 0) return; // Only allow left click to start drag
            dragOffset.current = {
                x: event.clientX - position.x,
                y: event.clientY - position.y,
            };
            document.addEventListener('mousemove', onDrag);
            document.addEventListener('mouseup', stopDrag);
        },
        [onDrag, stopDrag, position]
    );

    useEffect(() => {
        const headerEl = headerRef.current;
        if (headerEl) {
            headerEl.addEventListener('mousedown', startDrag);
        }
        return () => {
            headerEl?.removeEventListener('mousedown', startDrag);
        };
    }, [startDrag, id]);

    const modalStyle = {
        //position: 'fixed', // Ensure position is relative to the viewport
        left: `${position.x}px`,
        top: `${position.y}px`,
        margin: '0', // Override default margins to prevent shift
    };

    return (
        <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose} {...rest}>
            <ModalOverlay />
            <ModalContent boxShadow={'none'} bgColor={colorMode} style={modalStyle}>
                <Header isNoPadding={isNoPadding} ref={headerRef} title={title} onClose={onClose} />

                <ModalBody p={isNoPadding ? 0 : 7} fontFamily={'Inter'} fontWeight={'400'}>
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
});
