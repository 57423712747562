import React, { useCallback, useEffect, useState } from 'react';
import { hexToRgba } from '@utils/hexToRgba';

import {
    Box,
    Button,
    HStack,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Stack,
    Switch,
    Text,
    useColorMode,
} from '@chakra-ui/react';
import { PiCaretUpDownLight } from 'react-icons/pi'; // Adjust icon import
import { TSelectItem } from '../common/types';

interface OmiSelectColorListProps {
    id?: string;
    w?: string | number;
    list: TSelectItem[];
    value: string;
    onChange?: (code: string) => void;
    opaci?: number;
    isOpacity?: boolean;
}

export const OmiSelectColorList: React.FC<OmiSelectColorListProps> = React.memo(
    ({ id = 'input-color-1', w = '115px', list, value, onChange, opaci = 100, isOpacity = false }) => {
        const { colorMode } = useColorMode();
        const [selected, setSelected] = useState<string>(value);
        const [opacity, setOpa] = useState<number>(opaci);

        const _onChange = useCallback(
            (code: string) => {
                setSelected(code);
                setOpa(100);
                if (onChange) onChange(hexToRgba(code, 1));
            },
            [onChange]
        );

        useEffect(() => {
            if (onChange) onChange(hexToRgba(selected, opacity / 100));
        }, [opacity]);

        return (
            <Menu closeOnSelect={false}>
                <MenuButton
                    as={Button}
                    fontFamily="Inter"
                    borderRadius={3}
                    fontWeight="normal"
                    textAlign="left"
                    justifyContent="space-between"
                    w={w}
                    px={2}
                    size="xs"
                    fontSize="12px"
                    rightIcon={<Box as={PiCaretUpDownLight} />}
                >
                    <HStack>
                        <Box _hover={{ border: '1px solid #fff' }} w={3} h={3} bg={selected} />
                        <Text>{selected}</Text>
                    </HStack>
                </MenuButton>

                <MenuList p={1} bgColor={'Line' + colorMode} zIndex={999}>
                    <Text pl={2} fontSize={12}>
                        Colors
                    </Text>
                    <SimpleGrid mt={1} columns={6} spacing={0}>
                        {' '}
                        {/* Adjust the columns and spacing as needed */}
                        {list.map((item) => (
                            <MenuItem
                                px={2}
                                onClick={() => _onChange(item.Code)}
                                //_hover={{ bg: "gray.700" }}
                                bgColor="transparent"
                                key={item.Code}
                                borderRadius={3}
                            >
                                <Box _hover={{ border: '1px solid #fff' }} w={5} h={5} bg={item.Code} />
                            </MenuItem>
                        ))}
                    </SimpleGrid>
                    <HStack spacing={0} mx={2} my={2}>
                        <Input
                            id={`input-color-${id}`}
                            p={0}
                            m={0}
                            ml={'-2px'}
                            type="color"
                            size={'xs'}
                            border={0}
                            h={'30px'}
                            w={'29px'}
                            value={value}
                            onChange={(e) => _onChange(e.target.value)}
                        />
                        <Input
                            ml={-1}
                            borderLeft={0}
                            onClick={() => document.getElementById(`input-color-${id}`)?.click()}
                            p={0}
                            px={1}
                            size={'xs'}
                            type="text"
                            value={selected}
                            h={'22px'}
                            pl={2}
                            fontSize={11}
                            _focus={{ boxShadow: 'none' }}
                        />
                    </HStack>
                    {isOpacity && (
                        <Box p={2}>
                            {/*<SizeBox onChange={(val) => setOpa(val)} defaultValue={opacity} label='Opacity' /> */}

                            <Stack fontSize={12} spacing={1}>
                                <HStack justifyContent={'space-between'}>
                                    <Text>Opacity</Text>
                                    <HStack>
                                        <Text>Glass effect</Text> <Switch size={'sm'} />
                                    </HStack>
                                </HStack>
                                <Box>
                                    <Slider
                                        size={'sm'}
                                        onChange={(val) => setOpa(val)}
                                        aria-label="slider-ex-2"
                                        colorScheme="dark"
                                        defaultValue={opacity}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                    </Slider>
                                </Box>
                            </Stack>
                        </Box>
                    )}
                </MenuList>
            </Menu>
        );
    }
);
