import { Box, Progress } from '@chakra-ui/react';
import React from 'react';

export const Loading = React.memo(() => {
    return (
        <Box pos={'fixed'} width={'100%'} p={0} m={0} zIndex={2000} top={0} left={0}>
            <Progress size='xs' isIndeterminate bg={'orange'} />
        </Box>
    );
});

