/**
 * CONVERT ARRAY STRING => TSelectItem type
 */

import { TSelectItem } from '../common/types';

export const toSelectList = (list: string[]): TSelectItem[] => {
    return list.map((item) => {
        return {
            Code: item.replace(/\s/g, '-'),
            Label: item,
        } as TSelectItem;
    });
};
